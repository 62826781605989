@tailwind base;
@tailwind components;
@tailwind utilities;


/* ant design stylings >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
.ant-menu .ant-menu-item:hover,
.ant-menu-item-selected{
  background: #131619 !important;
  color: #F7941D !important;
}

.ant-table,
.ant-table
.ant-table-container
.ant-table-content
table
tbody.ant-table-tbody
.ant-table-row:hover td {
  background: transparent !important;
  color: white !important;
}

.ant-table
.ant-table-container
.ant-table-content
table
thead.ant-table-thead
.ant-table-cell {
  border-bottom: 1px solid #1A1D21 !important;
  background: transparent !important;
  color: #9B9C9E !important;
}

.ant-table
.ant-table-container
.ant-table-content
table
tbody.ant-table-tbody
.ant-table-cell {
  border-bottom: 1px solid #1A1D21 !important;
}





/* other stylings >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* audio::-webkit-media-controls-timeline-container{
  background: transparent !important;
  color: #fff !important;
} */

/* audio::-webkit-textfield-decoration-container{
  background: transparent !important;
  color: #fff !important;
} */

/* audio::-webkit-media-controls-play-button, */
/* audio::-webkit-media-controls-panel
audio::-webkit-scrollbar-track
{
  width: 100% !important;
  color: #fff !important;
  background: #131619 !important;
} */





/* progress stylings >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  height: 5px;
  background: #F7941D;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  opacity: 1;
  box-shadow: 0 0 10px #F7941D, 0 0 5px #F7941D;
  -webkit-transform: rotate(3deg) translate(0, -4px);
  -ms-transform: rotate(3deg) translate(0, -4px);
  transform: rotate(3deg) translate(0, -4px);
}

#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  width: rem(140);
  padding: rem(8) rem(15);
  background: #fff;
  border-radius: 4px;
  border: 1px solid #F7941D;
  right: auto;
  left: 50%;
  top: rem(13);
  margin-left: rem(-73);
}

#nprogress .spinner::after {
  content: 'Loading...';
  display: inline-block;
  position: absolute;
  top: rem(7);
  left: rem(48);
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: #F7941D;
  border-left-color: #F7941D;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
